.referalCode {
    display: flex;
    margin-top: 28px;
    height: 47px;
    border-radius: 12px;

    &__title {
        font-family: 'FuturaPT';
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        white-space: nowrap;
        color: #8B8B9E;
    }

    &__code,
    &__arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    &__code {
        font-family: FuturaPT;
        font-weight: 450;
    }

    &__codeWrapper {
        display: flex;
        flex-direction: column;
        width: 110px;
        font-family: 'FuturaPT';
        padding: 5px 10px;
        font-weight: 450;
        font-size: 18px;
        border-right: none !important;
        border-radius: 12px 0 0 12px;
        color: #fff;
    }

    &__arrow {
        width: 36px;
        border-radius: 0 12px 12px 0;
    }
}