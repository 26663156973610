.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    height: 56px;
    background-color: #262830;
    z-index: 1;

    @media (max-width: 700px) {
        padding: 0 20px;
    }

    &__logOut {
        font-family: 'FuturaPT';
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #fff;
        cursor: pointer;
    }
}

.contentReferalCode {
    position: relative;
    margin-left: auto;
}

.wrapperReferalCode {
    cursor: pointer;
}

.borderForCode {
    border: 2px dashed #A4A4A9;
}

.bgColorArrow {
    background-color: #A4A4A9;
}

.sizeCode {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.96px;
    color: #E0E0E0;
}

.alignItems {
    align-items: start;
}

.referalCode {
    margin-top: 0 !important;
    margin-right: 32px;

    @media (max-width: 700px) {
        margin-right: 10px;
    }
}