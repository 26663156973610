.formUserLogin {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 400px;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;

    &__title {
        margin-top: 17px;
        margin-bottom: 40px;
        font-family: 'FuturaPT';
        font-weight: 700;
        font-size: 46px;
        line-height: 49px;
        color: #F0F0F0;

        @media (max-width: 415px) {
            font-size: 40px;
            line-height: 43px;
        }

        @media (max-width: 370px) {
            font-size: 32px;
            line-height: 35px;
        }
    }

    &__submit {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 40px auto 0;
        padding: 18px 32px;
        height: 56px;
        font-family: FuturaPT;
        font-size: 14px;
        font-weight: 500;
        background-color: #CE1A30;
        color: #fff;
        border-radius: 88px;
        transition: background-color .2s ease;
        cursor: pointer;

        &:hover {
            background-color: #541ACE;
        }
    }

    &__haveReferralCode {
        display: inline-block;
        margin: 30px auto;
        font-family: 'FuturaPT';
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #8B8B9E;
        cursor: pointer;
    }

    &__inputWrapper {
        position: relative;
        width: 100%;

        &:not(:first-of-type) {
            margin-top: 20px;
        }
    }

    .customReactTelInput {
        color: #8B8B9E;
        font-family: 'FuturaPT';
        font-weight: 400;
        background-color: transparent !important;
        border: none !important;
        height: initial !important;
        line-height: initial !important;
        padding-left: 10px !important;
    }

    .flagDropdown {
        background-color: transparent !important;
        border: none !important;

        &:hover {
            div {
                background-color: transparent !important;
            }
        }

        div+ul li {
            display: flex;
            justify-content: start;
            align-items: center;
            background-color: #262830 !important;
            box-shadow: inset 0 -1px 0 hsla(0, 0%, 100%, .04);
            color: #e0e0e0;
            cursor: pointer;
            display: flex;
            font-family: FuturaPT;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            padding: 6px 20px;
            transition: background-color .2s ease;

            &:hover {
                background-color: #323540 !important;
            }

            span {
                font-family: 'FuturaPT';
                font-weight: 400;
            }
        }

        div+ul {
            width: 340px;
            margin-top: 2px;
            border-radius: 0 0 16px 16px;
            border: 2px solid #323540;
            border-top: none;
            background: #323540;

            &::-webkit-scrollbar-thumb {
                background-color: #4F525C;
                border-radius: 8px 8px;
            }

            &::-webkit-scrollbar {
                width: 6px;
                height: 8px;
            }

            &::-webkit-scrollbar-track {
                background-color: #8B8B9E;
                border-radius: 3px;
            }
        }
    }

    .flagDropdown div {
        background-color: transparent !important;
        padding-left: 0;
    }

    &__toggleTypeInput {
        position: absolute;
        right: 23px;
        top: calc(50% - 8.5px);
        font-family: 'FuturaPT';
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #fff;
        cursor: pointer;
    }

    &__messageErrorEnterRequiredFields {
        position: absolute;
        bottom: 60px;
        display: inline-block;
        max-width: 240px;
        padding: 9px 20px;
        background-color: #C40F24;
        border-radius: 12px;
        font-family: 'FuturaPT';
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #fff;

        @media (max-width: 600px) {
            top: -60px;
            bottom: initial;
        }
    }

    &__signature {
        max-width: 271px;
        margin: 0 auto;
        margin-top: 124px;

        span {
            font-family: 'FuturaPT';
            font-weight: 400;
            font-size: 14px;
            line-height: 23px;
            color: #8B8B9E;
        }

        @media (max-width: 600px) {
            margin-top: 50px;
        }

        a {
            color: #8B8B9E;
        }
    }

    &__link {
        border-bottom: solid 1px;
        font-family: 'FuturaPT';
        font-weight: 400;
        font-size: 14px;
    }

    .alreadyAccount {
        margin-top: 37px;
    }
}

.backIcon {
    position: absolute;
    top: 14px;
    left: 42px;
    cursor: pointer;
}