.input {
    display: inline-block;
    width: 100%;
    padding: 20px 23px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    background-color: #262830;
    border: 2px solid #323540;
    border-radius: 16px;
    color: #fff;
    transition: border .2s ease;
    caret-color: #ED5365;

    &::placeholder {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #8B8B9E;
        font-family: 'FuturaPT';
        font-weight: 400;
        font-size: 14px;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        -moz-appearance: textfield;
        background: #0a377e;
        margin: 0;
    }
}

.error {
    border: 2px solid #C40F24;
}