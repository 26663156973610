.welcome {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
        margin-top: 16px;
        color: #F0F0F0;
        font-family: FuturaPT;
        font-size: 46px;
        font-weight: 700;
        line-height: 49px;
        max-width: 350px;
        text-align: center;
    }

    .title_error {
        max-width: 520px;
    }

    &__subTitle,
    &__link {
        text-align: center;
        font-family: FuturaPT;
        font-size: 24px;
        font-weight: 400;
        line-height: 27px;
    }

    &__subTitle {
        max-width: 425px;
        margin-top: 40px;
        color: #8B8B9E;
    }

    &__link {
        color: #fff;
        font-weight: 500;
    }

    &__cat {
        margin-top: 40px;
        width: 263px;
        height: 190px;
        background-image: url('../../assets/images/cat.png');
        background-size: cover;
        background-repeat: no-repeat;
    }

}